import React from 'react';
import { Button, CircularProgress, Typography, Box, Divider } from '@mui/material';
import stripeLogo  from 'assets/stripeLogo.jpg'
import CloseIcon from '@mui/icons-material/Close';
import theme from 'theme';

interface ClientViewProps {
    isLoading: boolean;
    doStripeStuff: () => void;
    userData: any;
    subscriptionData: any;
}

const ClientView: React.FC<ClientViewProps> = ({ isLoading, doStripeStuff, userData, subscriptionData }) => (
    <Box mt={1}>
        <Box sx={{
            flex: 1, 
            boxShadow: 3, 
            borderRadius: 12, 
            width: {xs: 325, md: '100%'}, 
            mb: 6,
            backgroundColor: 'white',
            color: theme.palette.primary.main,
            minHeight: '500px',
            border: `2px solid ${theme.palette.primary.main}`,
        }}>
            <Box sx={{
                display: 'flex', 
                flexDirection: 'column',
                padding: '40px',
            }}>
                <Typography 
                    variant='h5' 
                    color={theme.palette.primary.main} 
                    sx={{ 
                        fontWeight: 'bold',
                        width: '100%',
                        mb: 1,
                    }}
                >
                    {userData.user_type === 'customer' ? 'Subscribed' : 'Not Subscribed'}
                </Typography>
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'flex-end',
                    mb: 4,
                }}>
                    <Typography 
                        variant='body1' 
                        color={theme.palette.primary.main}
                        sx={{ 
                            alignSelf: 'flex-start',
                            mt: 1,
                            fontSize: '1rem',
                        }}
                    >
                        $
                    </Typography>
                    <Typography 
                        variant='h3' 
                        color={theme.palette.primary.main}
                        sx={{ mr: 1 }}
                    >
                        50.00
                    </Typography>
                    <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        mb: 1,
                    }}>
                        <Typography 
                            variant='body2' 
                            color={theme.palette.primary.main}
                            sx={{ 
                                fontSize: '0.8rem',
                                lineHeight: 1,
                            }}
                        >
                            USD/
                        </Typography>
                        <Typography 
                            variant='body2' 
                            color={theme.palette.primary.main}
                            sx={{ 
                                fontSize: '0.8rem',
                            }}
                        >
                            month
                        </Typography>
                    </Box>
                </Box>

                <Typography variant='h5' color={theme.palette.primary.main} sx={{ mt: -1, mb: 2 }}>What you're missing:</Typography>
                <Box sx={{display:'flex', flexDirection: 'row', marginY: 1}}>
                    <CloseIcon sx={{ color: 'red', mr: 1 }}/>
                    <Typography variant='body1' color={theme.palette.primary.main} sx={{ fontWeight: 500, fontSize: '1.2rem' }}>Unlimited access to the Darby chatbot - Ask all the questions you want</Typography>
                </Box>
                <Box sx={{display:'flex', flexDirection: 'row', marginY: 1}}>
                    <CloseIcon sx={{ color: 'red', mr: 1 }}/>
                    <Typography variant='body1' color={theme.palette.primary.main} sx={{ fontWeight: 500, fontSize: '1.2rem' }}>Chat history - Continue any conversation from a previous session</Typography>
                </Box>
                <Box sx={{display:'flex', flexDirection: 'row', marginY: 1}}>
                    <CloseIcon sx={{ color: 'red', mr: 1 }}/>
                    <Typography variant='body1' color={theme.palette.primary.main} sx={{ fontWeight: 500, fontSize: '1.2rem' }}>PDF lookup - Find the exact source text for your queries directly from the PDF</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', marginY: 1 }}>
                    <CloseIcon sx={{ color: 'red', mr: 1 }}/>
                    <Typography variant='body1' color={theme.palette.primary.main} sx={{ fontWeight: 500, fontSize: '1.2rem' }}>Customize your chatbot to fit your needs</Typography>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex', 
                flexDirection: {md: 'row', xs: 'column'},
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                paddingTop: 0, 
                height:{md: 50, xs: 100},
                mt: -1,
                mb: -1
            }}>
                <Button 
                    variant="outlined" 
                    onClick={doStripeStuff} 
                    sx={{ 
                        width: { xs: '75%', md: '25%' }, 
                        mt: { xs: 1, md: 0 },
                        mb: -1,
                        color: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                        borderWidth: '2px',
                        borderRadius: '25px',
                        '&:hover': {
                            backgroundColor: 'white',
                            color: 'red',
                            borderColor: 'red',
                            borderWidth: '2px',
                        }
                    }}
                >
                    {isLoading ? 
                        <CircularProgress 
                            size={24}
                            sx={{
                                color: theme.palette.primary.main,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        /> 
                        : 'Subscribe'
                    }
                </Button>
            </Box>
        </Box>
    </Box>
);

export default ClientView;