import React, { useEffect, useState } from 'react';
import {
  useTheme,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Grid,
  Link
} from '@mui/material';
import { resetPassword, decodeResetToken } from 'core/api/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authTextFieldStyle } from 'theme';
import NotFound from 'components/NotFound';
import AuthLayout from 'layouts/AuthLayout';
import { verifyPasswordRequirements } from 'core/utils/verifyPasswordRequirements';

const ResetPassword = (): JSX.Element => {
  const navigate = useNavigate();
  const [resetSuccess, setResetSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState({ title: '', body: '', button: '' });
  const [searchParams] = useSearchParams();
  const [tokenStatus, setTokenStatus] = useState<'validating' | 'valid' | 'invalid' | 'expired'>('validating');

  
  const token = searchParams.get('token');

  const decodeTokenData = async () => {
    if (!token) {
      setTokenStatus('invalid');
      return;
    }
    try {
      const response = await decodeResetToken(token as string);
      {/* Reset Password Success */}
      if (response.status === 200) {
        setTokenStatus('valid');
      {/* Reset Password Link is expired, or another link has been sent afterward.*/}  
      } else if (response.status === 422) {
        setTokenStatus('expired');
        return;
      } else if (response.status === 409) {
        setTokenStatus('expired');
        return;
      } else {

      {/* Unable to decode token, redirect to 404 Not Found Page */}
        setTokenStatus('invalid');
      }
    } catch (error) {
      console.error(error);
      setTokenStatus('invalid');
    }
  }

  useEffect(() => {
    decodeTokenData();
  }, [token]);



  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const password = formData.get('password') as string;
    const passwordConfirmation = formData.get('passwordConfirmation') as string;

    if (!verifyPasswordRequirements(password)) {
      setDialogContent(
        {
          title: 'Password does not meet requirements.',
          body: 'Your password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number.',
          button: 'Ok'
        }
      )
      setOpenDialog(true);
      return;
    }

    if (password !== passwordConfirmation) {
      setDialogContent(
        {
          title: 'Passwords do not match',
          body: `You must confirm your password.`,
          button: 'Ok'
        }
      )
      setOpenDialog(true);
      return;
    }

    if (!token) {
      setDialogContent(
        {
          title: 'Something went wrong.',
          body: `Please try sending another password reset email.`,
          button: 'Ok'
        }
      )
      setOpenDialog(true);
      return;
    }


    const response = await resetPassword(password, token);

    if (response.status === 200) {
      setDialogContent(
        {
          title: 'Your password was reset successfully.',
          body: `We will now redirect you to the login page.`,
          button: 'Ok'
        }
      )
      setResetSuccess(true);
      setOpenDialog(true);
      return;
    } else if (response.status === 400) {
      setDialogContent(
        {
          title: 'Something went wrong.',
          body: `Please try sending another password reset email.`,
          button: 'Ok'
        }
      )
      setOpenDialog(true);
      return;
    }
  };


  const handleCloseDialog = () => {
    setOpenDialog(false);
    if (resetSuccess) {
      navigate('/login');
    }
  };

  const ResetPasswordDialog = (): JSX.Element => {
    return (<>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{dialogContent.title}</DialogTitle>
        <DialogContent>
          <Typography>{dialogContent.body}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {dialogContent.button}
          </Button>
        </DialogActions>
      </Dialog>
    </>);
  };

  const ResetTokenExpired = (): JSX.Element => {
    return (
      <AuthLayout>
        <Box sx={{ margin: '30px' }}>

          <Typography>
            Your password reset link has expired. Please try sending another password reset email.
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs>
            <Link href="/forgot-password" variant="body2">Forgot password?</Link>
          </Grid>
          <Grid item>
            <Link href="/login" variant="body2">{"Remember your password? Login"}</Link>
          </Grid>
        </Grid>
      </AuthLayout>
    );
  };

  if (tokenStatus === 'expired') {
    return (
      <ResetTokenExpired />
    );
  };

  if (tokenStatus === 'validating') {
    return <div></div>;
  };

  if (tokenStatus === 'invalid') {
    return <NotFound />;
  };

  return (
    <AuthLayout>
      <ResetPasswordDialog />
      <Box sx={{ marginTop: '20px' }}>
        <Typography >Please enter and confirm your new password. </Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          sx={authTextFieldStyle}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="passwordConfirmation"
          label="Confirm Password"
          type="password"
          id="passwordConfirmation"
          autoComplete="new-password"
          sx={authTextFieldStyle}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Reset Password
        </Button>
      </Box>
    </AuthLayout>
  );
}

export default ResetPassword;