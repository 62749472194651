import {
    doc,
    getDoc,
    getFirestore,
} from 'firebase/firestore';

const db = getFirestore();

interface ProfileHistoryEntry {
    type: 'human' | 'ai';
    content: string;
}

export const getProfileHistoryFromFirestore = async (userId: string): Promise<ProfileHistoryEntry[]> => {
    if (!userId) {
        throw Error("User ID is not defined");
    }

    try {
        const profileHistoryRef = doc(db, `users/${userId}/profile_history/profile`);
        const docData = await getDoc(profileHistoryRef);
        const profileHistoryMessages = docData.data();

        let profileHistoryOutput: ProfileHistoryEntry[] = [];

        if (profileHistoryMessages && profileHistoryMessages.messages) {
            const messages = profileHistoryMessages.messages;
            
            for (const message of messages) {
                try {
                    profileHistoryOutput.push({
                        type: message.type,
                        content: message.data.content || "",
                    });
                } catch (error) {
                    console.error("Error parsing profile history message:", message, error);
                }
            }
        }

        return profileHistoryOutput;
    } catch (error) {
        console.error("Error fetching profile history:", error);
        return [];
    }
};