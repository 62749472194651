import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { fetchPDFList } from 'core/api/fetchPDFList';
import {
  CircularProgress,
  Box,
  TextField,
  FormControlLabel,
  Switch,
  useTheme
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface PDFItem {
  name: string;
  url: string;
  last_updated: string;
  author: string;
  num_pages: string;
  pdf_url: string;
  title: string;
  type: string;
  functional_area: string;
  source_url: string;
  filename: string;
  publisher_category: string;
  publisher: string;
  company: string;
  last_modified: string;
}

interface KnowledgeBaseProps {
  open: boolean;
  onClose: () => void;
}

// Add type for GridItem props
interface GridItemProps {
  item: PDFItem;
}

// Add memo to prevent unnecessary re-renders of grid items
const GridItem = React.memo(({ item }: GridItemProps) => (
  <Grid item xs={12} md={6} lg={4}>
    <Paper
      component="div"
      elevation={3}
      sx={{
        padding: 2,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        borderRadius: '16px',
        color: 'black',
        border: '2px solid transparent',
        willChange: 'transform', // Optimize GPU rendering
      }}
    >
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6" gutterBottom noWrap>
          {item.title || item.name}
        </Typography>
        <Typography variant="body2">
          <strong>Author:</strong> {item.author || 'N/A'}
        </Typography>
        <Typography variant="body2">
          <strong>Type:</strong> {item.type || 'N/A'}
        </Typography>
        <Typography variant="body2">
          <strong>Pages:</strong> {item.num_pages || 'N/A'}
        </Typography>
        <Typography variant="body2">
          <strong>Published:</strong> {item.last_updated || 'N/A'}
        </Typography>
        <Typography variant="body2">
          <strong>Functional Area:</strong> {item.functional_area || 'N/A'}
        </Typography>
        <Typography variant="body2">
          <strong>Publisher:</strong> {item.publisher || 'N/A'} | <strong>Category:</strong> {item.publisher_category || 'N/A'}
        </Typography>
      </Box>
      <Box>
        <Link
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ 
            color: 'black',
            textDecoration: 'none',
            fontWeight: 'bold',
            '&:hover': { 
              color: 'red',
              textDecoration: 'underline',
              fontWeight: 'bold',
            } 
          }}
        >
          View Document
        </Link>
      </Box>
    </Paper>
  </Grid>
));

const KnowledgeBase: React.FC<KnowledgeBaseProps> = ({ open, onClose }) => {
  const [pdfItems, setPdfItems] = useState<PDFItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterPolicy, setFilterPolicy] = useState(false);
  const [filterGuidebook, setFilterGuidebook] = useState(false);
  const [showFormattingMessage, setShowFormattingMessage] = useState(false);
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null);
  const theme = useTheme();

  useEffect(() => {
    if (loading) {
      const newTimer = setTimeout(() => {
        setShowFormattingMessage(true);
      }, 3000);
      setTimer(newTimer);
    } else {
      setShowFormattingMessage(false);
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [loading, timer]);

  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const items = await fetchPDFList();
          // Sort the items alphabetically by title or name
          const sortedItems = items.sort((a, b) =>
            (a.title || a.name).localeCompare(b.title || b.name)
          );
          setPdfItems(sortedItems);
        } catch (error) {
          console.error("Failed to fetch PDF list:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    } else {
      // Optionally, clear the PDF items and filters when the dialog is closed
      setPdfItems([]);
      setSearchTerm('');
      setFilterPolicy(false);
      setFilterGuidebook(false);
    }
  }, [open]);

  // Move getFilteredItems before it's used
  const getFilteredItems = React.useCallback((
    items: PDFItem[], 
    searchTerm: string, 
    filterPolicy: boolean, 
    filterGuidebook: boolean
  ) => {
    const searchLower = searchTerm.toLowerCase();
    return items.filter((item: PDFItem) => {
      if (!searchLower && !filterPolicy && !filterGuidebook) return true;
      
      const matchesSearch = !searchLower || 
        (item.title && item.title.toLowerCase().includes(searchLower)) ||
        (item.author && item.author.toLowerCase().includes(searchLower)) ||
        (item.type && item.type.toLowerCase().includes(searchLower));

      if (!filterPolicy && !filterGuidebook) return matchesSearch;

      const typeLower = item.type.toLowerCase();
      return matchesSearch && ((filterPolicy && typeLower === 'policy') ||
        (filterGuidebook && typeLower === 'guidebook'));
    });
  }, []);

  const filteredPdfItems = useMemo(() => 
    getFilteredItems(pdfItems, searchTerm, filterPolicy, filterGuidebook),
    [pdfItems, searchTerm, filterPolicy, filterGuidebook, getFilteredItems]
  );

  return (
    <Dialog 
      fullWidth 
      maxWidth="lg" 
      open={open} 
      onClose={onClose} 
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.primary.main,
        },
      }}
    >
      <DialogTitle sx={{ position: 'relative', paddingRight: '48px' }}>
        <Typography variant="h4" sx={{ color: 'white' }}>Knowledge Base</Typography>
        <Typography sx={{ fontSize: '0.875rem', color: 'white' }}>
          Doc Count: {filteredPdfItems.length}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
            '&:hover': {
              color: 'red',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* Search and Filters */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            flexWrap: 'wrap',
            gap: 2,
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search Knowledge Base"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              flexGrow: 1,
              minWidth: '250px',
              flexBasis: '300px',
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                '&:hover fieldset': {
                  borderColor: 'white',
                },
              },
            }}
          />

          <FormControlLabel
            control={
              <Switch
                checked={filterPolicy}
                onChange={(e) => setFilterPolicy(e.target.checked)}
                sx={{
                  '& .MuiSwitch-switchBase': {
                    color: 'white',
                    '&.Mui-checked': {
                      color: 'red',
                      '& + .MuiSwitch-track': {
                        backgroundColor: 'red',
                      },
                    },
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: 'white',
                  },
                }}
              />
            }
            label="Policy"
            sx={{
              color: 'white',
            }}
          />

          <FormControlLabel
            control={
              <Switch
                checked={filterGuidebook}
                onChange={(e) => setFilterGuidebook(e.target.checked)}
                sx={{
                  '& .MuiSwitch-switchBase': {
                    color: 'white',
                    '&.Mui-checked': {
                      color: 'red',
                      '& + .MuiSwitch-track': {
                        backgroundColor: 'red',
                      },
                    },
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: 'white',
                  },
                }}
              />
            }
            label="Guidebook"
            sx={{
              color: 'white',
            }}
          />
        </Box>

        {/* Loading Indicator */}
        {loading ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
            <CircularProgress sx={{ color: 'white' }} />
            <Typography variant="body1" sx={{ mt: 2, color: 'white' }}>
              {showFormattingMessage ? "Formatting Display..." : "Retrieving All DoD Source Material..."}
            </Typography>
          </Box>
        ) : (
          /* PDF Items Grid */
          <Grid container spacing={2} sx={{ overflowY: 'auto', height: '100%' }}>
            {filteredPdfItems.map((item: PDFItem, index: number) => (
              <GridItem key={index} item={item} />
            ))}
            {filteredPdfItems.length === 0 && !loading && (
              <Box sx={{ width: '100%', textAlign: 'center', mt: 4 }}>
                <Typography variant="body1">No documents found.</Typography>
              </Box>
            )}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default KnowledgeBase;

