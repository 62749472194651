import { db } from "core/config/firebase";
import { doc, collection, query, where, onSnapshot } from "firebase/firestore";

export const getStripeSubStatusFromFirestore = async (userId: string): Promise<{ status: string, data: any }> => {
    if (!userId) {
        throw new Error("User ID is not defined");
    }

    try {
        const userDocRef = doc(db, "customers", userId);
        const subscriptionsRef = collection(userDocRef, "subscriptions");
        const subscriptionsQuery = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));

        return new Promise((resolve, reject) => {
            onSnapshot(subscriptionsQuery, (snapshot) => {
                const doc = snapshot.docs[0];
                if (doc) {
                    // console.log(doc.id, ' => ', doc.data());
                    resolve({ status: "customer", data: doc.data() });
                } else {
                    // console.log("No active or trialing subscriptions found.");
                    resolve({ status: "client", data: null });
                }
            }, (error) => {
                // console.error("Error fetching subscription status:", error);
                reject(error);
            });
        });
    } catch (error) {
        // console.error("Error fetching subscription status:", error);
        throw error;
    }
};

