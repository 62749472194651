import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Box,
} from "@mui/material";
import { useAuth } from "contexts/AuthContext";
import { getCurrentUserId } from "core/api/api";
import ClientView from "./ClientView";
import CustomerView from "./CustomerView";
import { getStripeSubStatusFromFirestore } from "core/api/getStripeSubStatusFromFirestore";
import { createCheckoutSession } from "@invertase/firestore-stripe-payments";
import { db } from "core/config/firebase";
import { doc, updateDoc } from "firebase/firestore";

type ManageSubscriptionProps = {
  userData: any;
  subscriptionData: any;
  handleOpenChat: () => void;
}

const ManageSubscription: React.FC<ManageSubscriptionProps> = ({ userData, subscriptionData, handleOpenChat }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionUserType, setSubscriptionUserType] = useState<string | null>(null);
  const { payments, userInfo } = useAuth();
  const uid = getCurrentUserId();
  const navigate = useNavigate();
  const userType = userInfo?.user_type;

  const fetchSubscriptionStatus = async () => {
    if (uid) {
      const { status } = await getStripeSubStatusFromFirestore(uid);
      setSubscriptionUserType(status);
      try {
        const userDocRef = doc(db, "users", uid);
        await updateDoc(userDocRef, {
          user_type: status, 
        });
        console.log("User type:", status); 
      } catch (error) {
        console.error("Error updating data:", error);
      }
    }
  };

  // Initial fetch of subscription status
  useEffect(() => {
    fetchSubscriptionStatus()
      .catch((error) => {
        console.error("Error fetching subscription status:", error);
      });
  }, [uid]); 

  // Handle return from Stripe checkout
  useEffect(() => {
    const stripeRedirect = localStorage.getItem("stripeRedirect");
    if (stripeRedirect) {
      localStorage.removeItem("stripeRedirect");
      setTimeout(() => {
        window.location.reload();
      }, 2000); // Wait for 2 seconds before refreshing. We need to wait for stripe webhook plus firestore writes

    // Set flag for opening chat after refresh
    setTimeout(() => {
    localStorage.setItem("openChatAfterRefresh", "true");
    }, 2000);
  }
  }, [handleOpenChat, navigate]);

  // Open chat if flag is set
  useEffect(() => {
    const openChatAfterRefresh = localStorage.getItem("openChatAfterRefresh");
    if (openChatAfterRefresh) {
      localStorage.removeItem("openChatAfterRefresh");
      handleOpenChat();
    }
  }, [handleOpenChat]);

  const doStripeStuff = async () => {
    if (!payments || !uid) return;

    setIsLoading(true);

    try {
      console.log("Firing Stripe checkout");
      const session = await createCheckoutSession(payments, {
        price: "price_1QG4LPG1pOntwkUFCGanYEK6", // product price id, Oct 31st 30 day free trial
        allow_promotion_codes: true,
        success_url: `${window.location.origin}/profile`,
        cancel_url: `${window.location.origin}/profile`,
      });

      localStorage.setItem("stripeRedirect", "true");
      window.location.assign(session.url);
    } catch (error) {
      console.error("Error creating Stripe session:", error);
      setIsLoading(false);
    }
  };

  const handleGoToChat = () => {
    navigate("/");
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {userType === "customer" ? (
            <CustomerView
              isLoading={isLoading}
              handleGoToChat={handleGoToChat}
              alterSubscriptionUrl="https://billing.stripe.com/p/login/00gbJy7jT18E4E03cc"
              userData={userData}
              subscriptionData={subscriptionData}
              handleUpdateProfile={handleOpenChat}
            />
          ) : (
            <ClientView
              isLoading={isLoading}
              doStripeStuff={doStripeStuff}
              userData={userData}
              subscriptionData={subscriptionData}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ManageSubscription;
