import { doc, setDoc, getFirestore } from 'firebase/firestore';

const db = getFirestore();

export const updateCurrentChatInFirestore = async (userId: string, sessionId: string): Promise<string> => {
    const userDocRef = doc(db, `users`, userId); 
    
    await setDoc(userDocRef, { currentChat: sessionId }, { merge: true });
    
    return sessionId;
};
