import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { NotFound, IsLoading } from 'components';

const useClientRoute = () => {
    const { isAuthenticated, userInfo } = useAuth();
    const [redirectTo, setRedirectTo] = useState<string | null>(null);
    const [checkingAuth, setCheckingAuth] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!isAuthenticated) {
                setRedirectTo('/login');
            } else if (userInfo && (userInfo.user_type !== 'client' && userInfo.user_type !== 'customer') || !userInfo?.user_type) {
                setRedirectTo('/not-found');
            } else if (userInfo && userInfo.user_type == 'client') {
                setRedirectTo('/profile')
            }
            else {
                setRedirectTo(null);
            }
            setCheckingAuth(false);
        }, 1000); // 1-second delay to communicate with fb, to better handle auto '/not-found' render

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, [isAuthenticated, userInfo]);

    return { isAuthenticated, redirectTo, checkingAuth };
};

const ClientRoute = ({ children }: any) => {
    const { isAuthenticated, redirectTo, checkingAuth } = useClientRoute();
    const navigate = useNavigate();

    useEffect(() => {
        if (redirectTo) {
            navigate(redirectTo);
        }
    }, [redirectTo, navigate]);

    if (checkingAuth) {
        return <IsLoading />; // Show loading state while authentication is being checked
    }


    if (!isAuthenticated) {
        return <NotFound />; // Show not-found if user is not authenticated
    }

    return children; // Render children if user is authenticated and redirection is not needed
};

export default ClientRoute;