import * as React from 'react';
import { Box, useTheme, IconButton, Tooltip } from '@mui/material';
import HistorySidebar from './components/HistorySidebar';
import { ChatProvider } from 'contexts/ChatContext';
import { MainLayout } from 'layouts';
import { useAuth } from 'contexts/AuthContext';
import Branch from './components/ChatBot/components/Branch';
import { Filter } from './components/ChatBot/components/Filter';
import { FeedbackForm } from './components/HistorySidebar/components/FeedbackForm';


export default function Home() {

    const { userInfo } = useAuth();

    const theme = useTheme();

    return (
        <MainLayout>
            <ChatProvider currentUser={userInfo}>
                <Branch/>
                <Filter/>
                <Box sx={{ width: '100%' }}>
                    <HistorySidebar />
                </Box>
            </ChatProvider>
        </MainLayout>

    );
}
