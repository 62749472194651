import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, Box, Button, Typography } from '@mui/material';
import { AuthLayout } from 'layouts';
import { useAuth } from 'contexts/AuthContext';
import { resendVerificationLink } from 'core/api/users'; 

const NeedVerification = () => {
    // const navigate = useNavigate();
    const { logout, setIsAuthenticated, userInfo } = useAuth();
    const theme = useTheme();

    const handleLoginRedirect = async () => {
        setIsAuthenticated(false);
        logout();
        window.location.href = '/login';
        // navigate('/login');
    };

    const handleResendVerification = async () => {
        // Resend verification email
        if (!userInfo) return;

        const email = userInfo.email;
        const firstName = userInfo.first_name;
        const lastName = userInfo.last_name;

        const response = await resendVerificationLink(email, firstName, lastName);
        if (response.ok) {
            // Show success message
            await logout();
            window.location.href = '/login';
            console.log('Verification email sent successfully');
        } else {
            // Show error message
            await logout();
            window.location.href = '/login';
            console.log('Error sending verification email');
        }


    };


    return (
        <AuthLayout>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: theme.spacing(2),
                    textAlign: 'center',
                }}
            >
            <Typography variant="h4" gutterBottom>
                Email Not Verified
            </Typography>
            <Typography variant="body1" gutterBottom>
                You have not yet verified your email address, please check your email for a verification link.
            </Typography>
            <Button 
            variant="contained"
             color="primary" 
             onClick={handleLoginRedirect}
             sx={{ mt: theme.spacing(2) }}
             >
                Go to Login
            </Button>
        </Box>

    </AuthLayout >
  );
    
//     return (
//         <AuthLayout>
//             <Box
//                 sx={{
//                     display: 'flex',
//                     flexDirection: 'column',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     margin: theme.spacing(2),
//                     textAlign: 'center',
//                 }}
//             >
//             <Typography variant="h4" gutterBottom>
//                 Email Not Verified
//             </Typography>
//             <Typography variant="body1" gutterBottom>
//                 You have not yet verified your email address, to resend the verification email, please click resend the button below.
//             </Typography>
//             <Button 
//             variant="contained"
//              color="primary" 
//              onClick={handleLoginRedirect}
//              sx={{ mt: theme.spacing(2) }}
//              >
//                 Go to Login
//             </Button>
//             <Button 
//             variant="contained"
//              color="primary" 
//              onClick={handleResendVerification}
//              sx={{ mt: theme.spacing(2) }}
//              >
//                 Resend Verification Email
//             </Button>
//         </Box>

//     </AuthLayout >
//   );
};

export default NeedVerification;
