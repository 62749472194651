import React, { useState } from 'react';
import { useChat } from 'contexts/ChatContext';
import { Box, Typography, useMediaQuery } from '@mui/material';
import DoD from 'assets/DoD.png'
import army from 'assets/army.png'
import navy from 'assets/navy.png'
import air_force from 'assets/air_force.png'
import theme from 'theme';

const Branch = () => {
    const { displayBranch } = useChat();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const getBranchIcon = (branch: string) => {
        switch (branch) {
            case 'Air Force':
                return air_force;
            case 'Navy':
                return navy;
            case 'Army':
                return army;
            case 'DoD':
                return DoD;
            default:
                return DoD;
        }
    };
    const branchIcon = getBranchIcon(displayBranch);
    const width = isMobile ?  "300" : "600"
    const height = isMobile ? "300" : "600"
    const positionH = isMobile ? "20%" : "8%"

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width= '10%'
            position="fixed"
            top={positionH}
            left="50%"
            sx={{ transform: 'translate(-50%, 0)', zIndex: -1, color: 'white'}}
            p={2}
        >
            {/* <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                {displayBranch ? `${displayBranch}` : ' '}
            </Typography> */}
            <img src ={branchIcon} width={width} height={height}  style={{ opacity: 0.05, pointerEvents: 'none' }} />
        </Box>
    );
};

export default Branch;
