import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
  ReactNode,
} from "react";
import { sendChatMessage } from "core/api/api";
import { getChatSessionsFromFirestore } from "core/api/getChatSessionsFromFirestore";
import { getChatHistoryFromFirestore } from "core/api/getChatHistoryFromFirestore";
import { updateCurrentChatInFirestore } from "core/api/updateCurrentChatInFirestore";
import {
  getFirestore,
  doc,
  collection,
  addDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { getCurrentUserId } from "core/api/api";
import { useLocation } from "react-router-dom";

const db = getFirestore();

interface ChatContextType {
  inputText: string;
  setInputText: (input: string) => void;
  history: HistoryEntry[];
  setHistory: (history: HistoryEntry[]) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  handleSubmit: () => Promise<void>;
  bottomRef: React.RefObject<HTMLDivElement>;
  selectedFilter: string | null;
  setSelectedFilter: (filter: string) => void;
  chatSessionList: HistorySession[];
  switchChat: (sessionId: string) => void;
  currentSessionId: string;
  initializeNewSession: () => void;
  createNewChat: () => void;
  firstChatLoading: { isLoading: boolean, index: number };
  displayBranch: string;
  setDisplayBranch: (displayBranch: string) => void;
  liveBranch: () => void;
  saveHistoryBool: boolean;
}

const ChatContext = createContext<ChatContextType | null>(null);

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within an ChatContext");
  }
  return context;
};

interface ChatProviderProps {
  children: ReactNode;
  currentUser: UserInfoType | null;
}

export const ChatProvider = ({ children, currentUser }: ChatProviderProps) => {
  const userId = getCurrentUserId() || "";
  const location = useLocation(); // Hook to detect route changes

  const [selectedFilter, setSelectedFilter] = useState("");
  const [inputText, setInputText] = useState("");
  const [chatSessionList, setChatSessionList] = useState<HistorySession[]>([]);
  const [history, setHistory] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [firstChatLoading, setFirstChatLoading] = useState({
    isLoading: false,
    index: -1,
  });
  const [currentSessionId, setCurrentSessionId] = useState<string>("");
  const [displayBranch, setDisplayBranch] = useState<string>("");
  const [saveHistoryBool, setSaveHistoryBool] = useState(true); // bool from firestore
  const [submitSessionId, setSubmitSessionId] = useState<string>("");
  const [activeSession, setActiveSession] = useState<string[]>([]);
  const [unusedNewChatId, setUnusedNewChatId] = useState<string | null>(null);

  const addId = (newId: string) => {
    setActiveSession((prevArray) => [...prevArray, newId]);
  };
  const removeId = (idToRemove: string) => {
    setActiveSession((prevArray) =>
      prevArray.filter((str) => str !== idToRemove)
    );
  };
  const isIdInArray = (searchString: string) => {
    return activeSession.includes(searchString);
  };

  // real-time updates to save history setting
  useEffect(() => {
    if (userId) {
      const userDocRef = doc(db, "users", userId);
      const unsubscribe = onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          const result = doc.data().save_history;
          setSaveHistoryBool(result);
          console.log("Save history:", result);
        }
      });
      // unmount
      return () => unsubscribe();
    }
  }, [userId]);

  // Effect to handle session flagging when saveHistoryBool changes
  useEffect(() => {
    if (!saveHistoryBool && currentSessionId) {
      // Mark the session for deletion when saveHistoryBool is set to false
      localStorage.setItem("sessionMarkedForDeletion", currentSessionId);
      // console.log("Session flagged for deletion:", currentSessionId);
    }
  }, [saveHistoryBool, currentSessionId]);

  // session deletion when the user navigates away
  // page refresh, change page, or closes the tab
   useEffect(() => {
    const handleBeforeUnloadOrNavigate = () => {
      if (!saveHistoryBool && currentSessionId) {
        // Mark the session for deletion
        localStorage.setItem("sessionMarkedForDeletion", currentSessionId);
      } else if (saveHistoryBool) {
        // If saveHistoryBool becomes true, unflag the session for deletion
        localStorage.removeItem("sessionMarkedForDeletion");
      }
    };

    if (!saveHistoryBool) {
      // Listen for unload events when history saving is disabled
      window.addEventListener("beforeunload", handleBeforeUnloadOrNavigate);

      // Listen for route changes
      const unlisten = () => {
        handleBeforeUnloadOrNavigate();
      };

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnloadOrNavigate);
        unlisten();
      };
    } else {
      // If saveHistoryBool is true, ensure no session is flagged for deletion
      localStorage.removeItem("sessionMarkedForDeletion");
    }
  }, [saveHistoryBool, currentSessionId, location]);

  // Effect to handle actual deletion if session was marked for deletion
  useEffect(() => {
    const sessionIdToDelete = localStorage.getItem("sessionMarkedForDeletion");

    if (sessionIdToDelete && currentSessionId === sessionIdToDelete && !saveHistoryBool) {
      const deleteMarkedSession = async () => {
        try {
          const chatRef = doc(
            db,
            `users/${userId}/chat_history/${sessionIdToDelete}`
          );
          await deleteDoc(chatRef);
          console.log("Deleted session:", sessionIdToDelete);
        } catch (error) {
          console.error("Failed to delete session:", error);
        } finally {
          localStorage.removeItem("sessionMarkedForDeletion");
        }
      };
      deleteMarkedSession();
    }
    else if (saveHistoryBool) {
      // If saveHistoryBool is restored to true, ensure no session is deleted
      localStorage.removeItem("sessionMarkedForDeletion");
    }
  }, [currentSessionId, userId]);

  useEffect(() => {
    if (currentUser) {
      const chatHistoryRef = collection(db, "users", userId, "chat_history");
      const unsubscribe = onSnapshot(chatHistoryRef, (snapshot) => {
        const chatList: HistorySession[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          if (!data) return;

          const lastMessage = data.messages[data.messages.length - 1];
          const timestamp = lastMessage?.data?.additional_kwargs?.timestamp;
          const name1 = data.messages[1]?.data?.chat_title;

          chatList.push({
            id: doc.id,
            name: name1 || '...',
            date_created: (timestamp * 1000).toString() || "date" + doc.id,
          });
        });

        const sortedChatList = chatList.sort(
          (a, b) => parseFloat(b.date_created) - parseFloat(a.date_created)
        );

        setChatSessionList(sortedChatList);

        if (sortedChatList.length > 0) {
          const lastChatSessionId = sortedChatList[0].id;
          setCurrentSessionId(lastChatSessionId);
          getChatHistoryFromFirestore(userId, lastChatSessionId).then(setHistory);
        }
      });

      return () => unsubscribe();
    }
  }, [currentUser, userId]);

  const createNewChat = async () => {
    if (!saveHistoryBool && currentSessionId) {
      // Delete the current chat session if the user does not want to save the history
      const chatRef = doc(db, `users/${userId}/chat_history/${currentSessionId}`);
      await deleteDoc(chatRef);
    }

    const sessionId = await initializeNewSession();
    setCurrentSessionId(sessionId);
    setHistory([]); // Clear the chat history
    setUnusedNewChatId(sessionId); // Track the new chat session
  };

  const switchChat = async (sessionId: string) => {
    // Delete unused new chat if exists
    if (unusedNewChatId && unusedNewChatId !== sessionId) {
      try {
        const unusedChatRef = doc(
          db,
          `users/${userId}/chat_history/${unusedNewChatId}`
        );
        await deleteDoc(unusedChatRef);
        console.log("Deleted unused new chat:", unusedNewChatId);
      } catch (error) {
        console.error("Error deleting unused chat:", error);
      }
    }
    
    setUnusedNewChatId(null); // Clear the tracked unused chat
    setCurrentSessionId(sessionId);
    if (isIdInArray(sessionId)){
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
    
    await updateCurrentChatInFirestore(userId, sessionId);
    console.log("Switching to chat:", sessionId);
    const messages = await getChatHistoryFromFirestore(userId, sessionId);
    setHistory(messages);
  };

  const handleSubmit = async () => {
    if (!inputText.trim()) return;
    setIsLoading(true);
    scrollToBottom();
    
    try {
      console.log("currentSessionId in submit:", currentSessionId);
      setSubmitSessionId(currentSessionId);
      addId(currentSessionId);
      setUnusedNewChatId(null); // Clear unused chat tracking when message is sent

      let data: any;

      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      let latestInstructions = "";

      // Grab chat_custom_instructions value
      if (userDoc.exists()) {
        latestInstructions = userDoc.data().chat_custom_instructions || "";
        // console.log('latestInstructions', latestInstructions)
      }
      const inputTextWithInstructions = `${inputText} ${latestInstructions}`;

      if (!currentSessionId) {
        setFirstChatLoading((prevState) => ({ ...prevState, isLoading: true }));
        const sessionId = await initializeNewSession();
        setCurrentSessionId(sessionId);
        data = await sendChatMessage(
          inputTextWithInstructions,
          sessionId,
          selectedFilter
        );
        setFirstChatLoading({ isLoading: false, index: -1 });
      } else {
        data = await sendChatMessage(
          inputTextWithInstructions,
          currentSessionId,
          selectedFilter
        );
      }

      const parsed_data = JSON.parse(data);
      const newHistory = [
        ...history,
        { user: inputTextWithInstructions, darby: parsed_data },
      ];
      setHistory(newHistory);
      scrollToBottom();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
      setInputText("");
      removeId(currentSessionId);
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const initializeNewSession = async () => {
    const chatHistoryRef = collection(db, `users/${userId}/chat_history`);
    const docRef = await addDoc(chatHistoryRef, {
      createdAt: new Date().getTime().toString(), //new Date(),
      messages: [],
      name: "New Chat Session",
    });

    console.log("New session created with ID:", docRef.id);

    const newSession = {
      id: docRef.id,
      name: docRef.id,
      date_created: new Date().getTime().toString()//.toISOString()
    };

    setChatSessionList((prevList) => {
      const updatedList = [...prevList, newSession];
      const newSessionIndex = updatedList.length - 1;
      setFirstChatLoading((prevState) => ({
        ...prevState,
        index: newSessionIndex,
      }));
      return updatedList;
    });

    setCurrentSessionId(docRef.id);
    await updateCurrentChatInFirestore(userId, docRef.id);
    setHistory([]); // Clear the chat history
    console.log("New session created with ID:", docRef.id);
    return docRef.id;
  };

  const liveBranch = async () => {
    const userId = getCurrentUserId();
    if (userId) {
      
    try {
      const userDocRef = doc(db, 'users', userId);
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setDisplayBranch(data.document_filter);
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  }
}


  useEffect(() => {
    const loadAndRefreshChatHistoryList = async () => {
      if (currentUser && currentSessionId) {
        console.log("current session id is", currentSessionId);
        const messages = await getChatHistoryFromFirestore(
          userId,
          currentSessionId
        );
        setHistory(messages);
        scrollToBottom();
      }
    };
    liveBranch();

    if (!firstChatLoading.isLoading) loadAndRefreshChatHistoryList();
  }, [currentUser, currentSessionId, firstChatLoading.isLoading]);

  const value = {
    inputText,
    setInputText,
    history,
    setHistory,
    isLoading,
    setIsLoading,
    handleSubmit,
    bottomRef,
    selectedFilter,
    setSelectedFilter,
    chatSessionList,
    switchChat,
    currentSessionId,
    initializeNewSession,
    createNewChat,
    firstChatLoading,
    setDisplayBranch,
    displayBranch,
    saveHistoryBool,
    liveBranch,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};