import React, { useEffect, useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Box,
    useMediaQuery
} from '@mui/material';
import { useChat } from 'contexts/ChatContext';
import { db } from 'core/config/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getCurrentUserId } from 'core/api/api';
import theme from 'theme';

export const Filter = () => {
    const { selectedFilter, setSelectedFilter } = useChat();
    const userId = getCurrentUserId();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const { liveBranch } = useChat();

    useEffect(() => {
        const fetchUserSetting = async () => {
            if (userId) {
                const userDocRef = doc(db, 'users', userId);
                try {
                    const docSnap = await getDoc(userDocRef);
                    if (docSnap.exists()) {
                        const docFilter = docSnap.data().document_filter;
                        setSelectedFilter(docFilter ?? "DoD");
                    }
                } catch (error) {
                    console.error('Error fetching document: ', error);
                }
            }

        };

        fetchUserSetting();
    }, [userId]);

    const handleFilterChange = async (event: SelectChangeEvent<string | null>) => {
        const value = event.target.value;
        setSelectedFilter(value ?? 'DoD');
        if (userId) {
            const userDocRef = doc(db, 'users', userId);
            try {
                await updateDoc(userDocRef, {
                    document_filter: value
                });
                console.log('Document updated successfully');
            } catch (error) {
                console.error('Error updating document: ', error);
            }
        }
        liveBranch();
    };

    const branchColorBg = (branch: string) => {
        switch (branch) {
            case 'Air Force':
                return '#6CA6D9';
            case 'Navy':
                return '#003366';
            case 'Army':
                return '#004d00';
            case 'DoD':
                return '#000000';
            default:
                return '#000000';
        }
    };

    const branchColorText = (branch: string) => {
        switch (branch) {
            case 'Air Force':
                return '#FFFFFF';
            case 'Navy':
                return '#FFFFFF';
            case 'Army':
                return '#FFD700';
            case 'DoD':
                return '#FFFFFF';
            default:
                return '#FFFFFF';
        }
    };




    useEffect(() => {
        const fetchUserSetting = async () => {
            if (userId) {
                const userDocRef = doc(db, 'users', userId);
                try {
                    const docSnap = await getDoc(userDocRef);
                    if (docSnap.exists()) {
                        const docFilter = docSnap.data().document_filter;
                        setSelectedFilter(docFilter ?? "dod");
                    }
                } catch (error) {
                    console.error('Error fetching document: ', error);
                }
            }

        };

        fetchUserSetting();
    }, [userId]);

    const top = isMobile ? '-0.6%' : '-0.5%'

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="30%"
            position="fixed"
            top={top}
            left="50%"
            sx={{ transform: 'translate(-50%, 0)', zIndex: 1200 }}
            p={2}
        >
            <FormControl sx={{ width: { xs: '75%', md: '25%' } }}>
                <Select
                    labelId="document-type-label"
                    id="document-type-select"
                    value={selectedFilter}
                    onChange={handleFilterChange}
                    label="Document Type"
                    style={{ textAlign: 'left', height: isMobile ? '35px' : '40px', width: '120px' }}
                    MenuProps={{
                        disableScrollLock: true,
                        PaperProps: {
                            sx: {
                                maxHeight: '300px',
                            }
                        }
                    }}
                    sx={{
                        color: branchColorText(selectedFilter || ""),
                        fontSize: isMobile ? '1rem' : '1.1rem',
                        background: branchColorBg(selectedFilter || ""),
                        border: branchColorText(selectedFilter || ""),
                        '& .MuiSelect-icon': {
                            color:  branchColorText(selectedFilter || ""),
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none'
                            },
                            '&:hover fieldset': {
                                border: 'none'
                            },
                        },
                    }}
                >
                    <MenuItem value="DoD">DoD</MenuItem>
                    <MenuItem value="Army">Army</MenuItem>
                    <MenuItem value="Navy">Navy</MenuItem>
                    <MenuItem value="Air Force">Air Force</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};


