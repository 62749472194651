import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, IconButton, Typography, Paper, Avatar, useTheme, CircularProgress, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { sendProfileUpdateMessage, getCurrentUserId } from 'core/api/api';
import { getProfileHistoryFromFirestore } from 'core/api/getProfileHistoryFromFirestroe';
import { getFirestore, deleteDoc, doc, getDoc } from 'firebase/firestore';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';

interface ProfileBotProps {
    uid: string;
    onClose: () => void;
}

interface ProfileHistoryEntry {
    type: 'human' | 'ai';
    content: string;
}

const ProfileBot: React.FC<ProfileBotProps> = ({ uid, onClose }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [inputText, setInputText] = useState('');
    const [conversation, setConversation] = useState<ProfileHistoryEntry[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showFormattingMessage, setShowFormattingMessage] = useState(false);
    const userId = getCurrentUserId();
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const db = getFirestore();
    let timer: NodeJS.Timeout;
    const [aiMessageCount, setAiMessageCount] = useState(0);
    const [profileSummary, setProfileSummary] = useState<string | null>(null);

    useEffect(() => {
        if (userId) {
            const fetchProfileHistory = async () => {
                try {
                    const history = await getProfileHistoryFromFirestore(userId);
                    setConversation(history);
                    const aiMessages = history.filter(entry => entry.type === 'ai').length;
                    setAiMessageCount(aiMessages);
                    scrollToBottom(); // Ensure scrolling after fetching history
                } catch (error) {
                    console.error('Error fetching profile history:', error);
                }
            };
            fetchProfileHistory();
        }
    }, [userId]);

    useEffect(() => {
        scrollToBottom(); // Scroll to bottom whenever conversation changes
    }, [conversation]);

    useEffect(() => {
        if (isLoading) {
            timer = setTimeout(() => {
                setShowFormattingMessage(true);
            }, 15000);
        } else {
            setShowFormattingMessage(false);
        }

        return () => clearTimeout(timer);
    }, [isLoading]);

    useEffect(() => {
        const aiMessages = conversation.filter(entry => entry.type === 'ai').length;
        setAiMessageCount(aiMessages);

        if (aiMessages >= 3) {
            const fetchProfileSummary = async () => {
                try {
                    if (userId) {
                        const docRef = doc(db, "users", userId);
                        const docSnap = await getDoc(docRef);
                        if (docSnap.exists()) {
                            const data = docSnap.data();
                            setProfileSummary(data.chat_custom_instructions || "No summary available.");
                        }
                    }
                } catch (error) {
                    console.error("Error fetching profile summary:", error);
                }
            };
            fetchProfileSummary();
        } else {
            setProfileSummary(null); // Clear profile summary if AI messages are less than 3
        }
    }, [conversation, userId]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputText(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && !isLoading) {
            event.preventDefault();
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        if (userId && inputText.trim() && aiMessageCount < 3) {
            const newMessage: ProfileHistoryEntry = { type: 'human', content: inputText };
            setConversation(prev => [...prev, newMessage]);
            setInputText('');
            setIsLoading(true);
            try {
                await sendProfileUpdateMessage(inputText);
                const updatedHistory = await getProfileHistoryFromFirestore(userId);
                setConversation(updatedHistory);
                const aiMessages = updatedHistory.filter(entry => entry.type === 'ai').length;
                setAiMessageCount(aiMessages);
            } catch (error) {
                console.error('Error sending profile update message:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleClearChat = async () => {
        if (userId) {
            try {
                const userDocRef = doc(db, `users/${userId}/profile_history/profile`);
                await deleteDoc(userDocRef);
                setConversation([]);
                setAiMessageCount(0); // Reset AI message count
                setProfileSummary(null); // Clear profile summary
            } catch (error) {
                console.error('Error clearing profile collection:', error);
            }
        }
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 100);
    };

    return (
        <Paper elevation={3} sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            bgcolor: theme.palette.background.paper,
            boxShadow: theme.shadows[3]
        }}>
            {/* Header */}
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
                bgcolor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ width: 40, height: 40, mr: 2, bgcolor: theme.palette.secondary.main }}>
                        <SmartToyIcon />
                    </Avatar>
                    <Typography variant="h6">Profile Update Chat</Typography>
                </Box>
                <Box>
                    <Button
                        onClick={handleClearChat}
                        sx={{
                            color: theme.palette.common.white,
                            textTransform: 'none',
                            border: `1px solid ${theme.palette.common.white}`,
                            bgcolor: theme.palette.primary.main,
                            '&:hover': {
                                color: theme.palette.primary.main,
                                bgcolor: theme.palette.common.white,
                                borderColor: theme.palette.primary.main,
                            },
                            mr: 1,
                        }}
                    >
                        Reset Profile
                    </Button>
                    <IconButton onClick={onClose} color="inherit">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            {/* Initial Questions */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    mb: 2,
                    p: 2,
                    bgcolor: theme.palette.grey[100],
                    borderRadius: theme.shape.borderRadius,
                }}
            >
                <Avatar
                    sx={{
                        bgcolor: theme.palette.secondary.main,
                        color: theme.palette.common.white,
                        width: 32,
                        height: 32,
                        mr: 1,
                    }}
                >
                    <SmartToyIcon />
                </Avatar>
                <Box>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
                        Welcome! To set up your profile, please start by answering the following questions:
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1, color: theme.palette.text.secondary }}>
                        1) Which DoD organization are you most interested in: Airforce, Navy, Army, etc?<br />
                        2) Are you most interested in organization-specific policies, guidebooks, or both?<br />
                        3) What are your goals for using this platform?<br />
                    </Typography>
                </Box>
            </Box>
            {/* Chat messages */}
            <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2, display: 'flex', flexDirection: 'column' }}>
                {conversation.map((message, index) => {
                    const aiMessagesBeforeThis = conversation.slice(0, index).filter(msg => msg.type === 'ai').length;
                    if (message.type === 'ai' && aiMessagesBeforeThis === 2) {
                        return null;
                    }
                    return (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: message.type === 'human' ? 'flex-end' : 'flex-start',
                                mb: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: message.type === 'human' ? 'row-reverse' : 'row',
                                    alignItems: 'flex-end',
                                    maxWidth: '80%',
                                }}
                            >
                                <Avatar
                                    sx={{
                                        bgcolor: message.type === 'human' ? theme.palette.primary.main : theme.palette.secondary.main,
                                        color: theme.palette.common.white,
                                        width: 32,
                                        height: 32,
                                    }}
                                >
                                    {message.type === 'human' ? <PersonIcon /> : <SmartToyIcon />}
                                </Avatar>
                                <Paper
                                    elevation={0}
                                    sx={{
                                        p: 1.5,
                                        borderRadius: theme.shape.borderRadius,
                                        bgcolor: message.type === 'human' ? theme.palette.primary.main : theme.palette.grey[100],
                                        color: message.type === 'human' ? theme.palette.primary.contrastText : theme.palette.text.primary,
                                        ml: message.type === 'human' ? 0 : 1,
                                        mr: message.type === 'human' ? 1 : 0,
                                    }}
                                >
                                    <Typography variant="body2">
                                        {message.content}
                                    </Typography>
                                </Paper>
                            </Box>
                        </Box>
                    );
                })}
                {profileSummary && (
                    <Box sx={{ 
                        mb: 2, 
                        p: 2, 
                        bgcolor: theme.palette.background.paper, 
                        borderRadius: theme.shape.borderRadius, 
                        boxShadow: theme.shadows[1],
                        border: `1px solid ${theme.palette.divider}`,
                        textAlign: 'center'
                    }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
                            Profile Summary
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1, color: theme.palette.text.secondary }}>
                            {profileSummary}
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2, textTransform: 'none', mx: 'auto' }}
                            onClick={() => navigate('/')}
                        >
                            We've collected enough information to fine-tune your experience! Get started
                        </Button>
                    </Box>
                )}
                {isLoading && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            mb: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                maxWidth: '80%',
                            }}
                        >
                            <Avatar
                                sx={{
                                    bgcolor: theme.palette.secondary.main,
                                    color: theme.palette.common.white,
                                    width: 32,
                                    height: 32,
                                }}
                            >
                                <SmartToyIcon />
                            </Avatar>
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 1.5,
                                    borderRadius: theme.shape.borderRadius,
                                    bgcolor: theme.palette.grey[100],
                                    ml: 1,
                                }}
                            >
                                <CircularProgress size={20} />
                            </Paper>
                        </Box>
                    </Box>
                )}

                <div ref={messagesEndRef} />
            </Box>
            <Box sx={{ display: 'flex', p: 2, borderTop: `1px solid ${theme.palette.divider}`, bgcolor: theme.palette.background.default }}>
                <TextField
                    placeholder="Type your message"
                    variant="outlined"
                    value={inputText}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    size="small"
                    disabled={isLoading || aiMessageCount >= 3}
                    sx={{
                        mr: 1,
                        '& .MuiOutlinedInput-root': {
                            borderRadius: theme.shape.borderRadius,
                            bgcolor: theme.palette.background.paper,
                        }
                    }}
                />
                <IconButton
                    onClick={handleSubmit}
                    disabled={isLoading || aiMessageCount >= 3}
                    sx={{
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.common.white,
                        '&:hover': {
                            bgcolor: theme.palette.primary.dark,
                        },
                        width: 40,
                        height: 40,
                    }}
                >
                    <SendIcon />
                </IconButton>
            </Box>
        </Paper>
    );
};

export default ProfileBot;
