import { getHeaders } from "core/api/api";
import { httpCodes } from "core/constants/httpCodes";
import { deleteUserFirestoreProfile } from "core/api/firestore";

export const deleteFirebaseUser = async (userId: any) => {
  // This will delete a user along with all objects associated with that user via UID via the extension

  // const functionUrl = "http://127.0.0.1:5001/otw-production-91ffb/us-central1/deleteFirebaseUser"
  const functionUrl =
    "https://us-central1-otw-production-91ffb.cloudfunctions.net/deleteFirebaseUser";


  const res = await fetch(functionUrl, {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify({ userId }),
  });
  
    await deleteUserFirestoreProfile(userId);
  
  
    return res.json();
  

};


export const createFirebaseUser = async (userData: any) => {
    // const functionUrl = "http://127.0.0.1:5001/otw-production-91ffb/us-central1/createFirebaseUser"
    const functionUrl =
  'https://us-central1-otw-production-91ffb.cloudfunctions.net/createFirebaseUser'
    const res = await fetch(functionUrl, {
      method: "POST",
      headers: await getHeaders(),
      body: JSON.stringify(userData),
    });
    if (res.status === 200) {
      return "Created";
    } else {
      const status = res.status
      const message = httpCodes[status] || "An error occurred, please contact Chase."
      return message;
    }
  };

  

export const resendVerificationEmail = async (email: string) => {
//   const functionUrl =
//     "http://127.0.0.1:5001/otw-production-91ffb/us-central1/resendVerificationEmail";
//   
  const functionUrl = "https://us-central1-freetech-production.cloudfunctions.net/deleteFirebaseUser"

  const res = await fetch(functionUrl, {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify({ email }),
  });
  if (res.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const sendRequestedUserEmail = async (email: string, first_name: string, last_name: string) => {
    const functionUrl = "http://127.0.0.1:5001/aa-fb-prod/us-central1/sendEmail"
    // const functionUrl ='https://us-central1-aa-fb-prod.cloudfunctions.net/sendEmail'

    const res = await fetch(functionUrl, {
      method: "POST",
      headers: await getHeaders(),
      body: JSON.stringify({email, first_name, last_name}),
    });
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };
  
  // ^^^  ALL FUNCTIONS ABOVE UNUSED, BUT DEPENDANT BY AUTHCONTEX ^^^ 

  export const sendVerificationEmail = async (email: string | null, name: string | null) => {
    try {
      const response = await fetch('https://us-central1-aa-darby-prod-7fef4.cloudfunctions.net/mail-sendVerificationEmail', {
        method: 'POST',
        headers: await getHeaders(),
        body: JSON.stringify({email, name}),
      });
  
      // Check if the request was successful
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error sending email: ${errorData.message}`);
      }
  
      const result = await response.json();
      console.log("Verification email sent:");
      return result.message;
      
    } catch (error) {
      console.error("Error sending verification email:", error);
      throw error;
    }
  };

  export const sendUserFeedback = async (name: string, email: string, feedback: string, rating: number) => {
    try {
      const response = await fetch('https://us-central1-aa-darby-prod-7fef4.cloudfunctions.net/mail-sendUserFeedback', {
        method: 'POST',
        headers: await getHeaders(),
        body: JSON.stringify({ name, email, feedback, rating }),
      });
  
      // Check if the request was successful
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error submitting feedback: ${errorData.message}`);
      }
  
      const result = await response.json();
      console.log("Feedback submitted successfully:", result);
      return result.message;
  
    } catch (error) {
      console.error("Error submitting feedback:", error);
      throw error;
    }
  };
  
export const submitSupportRequest = async (issueType: string, description: string): Promise<any> => {
  try {
      const response = await fetch(`https://us-central1-aa-darby-prod-7fef4.cloudfunctions.net/mail-submitSupportRequest`, {
          method: 'POST',
          headers: await getHeaders(), 
          body: JSON.stringify({ issueType, description }), 
      });

      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      return data;
  } catch (error) {
      console.error('Error in submit support request:', error);
      throw error;
  }
};

