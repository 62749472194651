import { storage } from 'core/config/firebase';
import { ref, listAll, getDownloadURL, getMetadata } from 'firebase/storage';

interface PDFItem {
  name: string;
  url: string;
  last_updated: string;
  author: string;
  num_pages: string;
  pdf_url: string;
  title: string;
  type: string;
  functional_area: string;
  source_url: string;
  filename: string;
  publisher_category: string;
  publisher: string;
  company: string;
  last_modified: string;
}

export const fetchPDFList = async (): Promise<PDFItem[]> => {
  try {
    const listRef = ref(storage, 'local_store/');
    const res = await listAll(listRef);
    const items = await Promise.all(
      res.items.map(async (item) => {
        const url = await getDownloadURL(item);
        const metadata = await getMetadata(item);
        return {
          name: item.name,
          url,
          last_updated: metadata.customMetadata?.last_updated || '',
          author: metadata.customMetadata?.author || '',
          num_pages: metadata.customMetadata?.num_pages || '',
          pdf_url: metadata.customMetadata?.pdf_url || '',
          title: metadata.customMetadata?.title || '',
          type: metadata.customMetadata?.type || '',
          functional_area: metadata.customMetadata?.functional_area || '',
          source_url: metadata.customMetadata?.source_url || '',
          filename: metadata.customMetadata?.filename || '',
          publisher_category: metadata.customMetadata?.publisher_category || '',
          publisher: metadata.customMetadata?.publisher || '',
          company: metadata.customMetadata?.company || '',
          last_modified: metadata.customMetadata?.last_modified || '',
        };
      })
    );
    return items;
  } catch (error) {
    console.error('Error fetching PDF list:', error);
    return [];
  }
};
