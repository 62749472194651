import React, { useState } from "react";
import {
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
// import { VerificationSuccessDialog } from './components/VerificationSuccessDialog';
import AuthLayout from "layouts/AuthLayout";
import { authTextFieldStyle } from "theme";
import { resendVerificationEmail } from "core/api/functions";
import { FcGoogle } from "react-icons/fc";

const Login = () => {
  const navigate = useNavigate();
  const {
    loginWithEmail,
    loginWithGoogle,
    setIsAuthenticated,
    setUserInfo,
    isVerified,
  } = useAuth();
  const [openDialog, setOpenDialog] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [dialogContent, setDialogContent] = useState({
    title: "",
    body: "",
    button: "",
    resendVerification: "",
    resetPassword: "",
  });
  const theme = useTheme();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const credentials = {
      email: formData.get("email") as string,
      password: formData.get("password") as string,
    };
  
    setCurrentEmail(credentials.email);
    const response = await loginWithEmail(credentials);
    if (response && isVerified()) {
      // Start a 5-second wait
      setDialogContent({
        title: "Login Successful",
        body: "Please wait while we redirect you...",
        button: "Ok",
        resendVerification: "",
        resetPassword: "",
      });
      setOpenDialog(true);
      
      // Wait for 5 seconds
      await new Promise(resolve => setTimeout(resolve, 5000));
      
      setIsAuthenticated(true);
      setOpenDialog(false);
    } else if (response === false) {
      setDialogContent({
        title: "Incorrect username or password.",
        body: `Please make sure you entered your username and password correctly.`,
        button: "Ok",
        resendVerification: "",
        resetPassword: "",
      });
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/login");
  };

  const navigateToResetPassword = async () => {
    setOpenDialog(false);
    navigate("/reset-password?token=" + resetToken);
  };

  const LoginDialog = () => {
    return (
      <>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{dialogContent.title}</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent.body}</Typography>
          </DialogContent>
          <DialogActions>
            {!dialogContent.resetPassword && (
              <Button onClick={handleCloseDialog} color="primary">
                {dialogContent.button}
              </Button>
            )}
            {dialogContent.resendVerification && (
              <Button onClick={navigateToResetPassword} color="primary">
                {dialogContent.resendVerification}
              </Button>
            )}
            {dialogContent.resetPassword && (
              <Button onClick={navigateToResetPassword} color="primary">
                {dialogContent.button}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return (
    <AuthLayout>
      {/* Dialogs */}
      {/* <AuthErrorDialog /> */}
      <LoginDialog />

      {/* Login Form */}
      <Box component="form" onSubmit={handleSubmit} noValidate marginTop={1}>
        <TextField
          required
          fullWidth
          margin="normal"
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          sx={authTextFieldStyle}
          autoFocus
        />
        <TextField
          required
          fullWidth
          margin="normal"
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          sx={authTextFieldStyle}
        />

        <Button
          fullWidth
          type="submit"
          variant="contained"
          sx={{ mt: 1, mb: 2 }}
        >
          {" "}
          {/* Increased the bottom margin */}
          Sign In
        </Button>

        {/* Links */}
        <Grid container>
          <Grid item xs>
            <Link href="/forgot-password" variant="body2">
              {"Forgot password?"}
            </Link>
          </Grid>
          <Grid item>
            <Link href="/signup" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </AuthLayout>
  );
};

export default Login;