import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, Box, Button, Typography } from '@mui/material';
import { AuthLayout } from 'layouts';
import { useAuth } from 'contexts/AuthContext';

const SessionExpired = () => {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const theme = useTheme();

    const handleLoginRedirect = () => {
        logout();
        navigate('/login');
    };

    return (
        <AuthLayout>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: theme.spacing(2),
                    textAlign: 'center',
                }}
            >
            <Typography variant="h4" gutterBottom>
                Session Expired
            </Typography>
            <Typography variant="body1" gutterBottom>
                Your session has expired. Please log in again to continue.
            </Typography>
            <Button 
            variant="contained"
             color="primary" 
             onClick={handleLoginRedirect}
             sx={{ mt: theme.spacing(2) }}
             >
                Go to Login
            </Button>
        </Box>

    </AuthLayout >
  );
};

export default SessionExpired;
