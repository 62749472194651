import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  Link,
  Typography,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  MenuItem,
  Tooltip,
  IconButton,
  Menu,
  Avatar,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemIcon,
  ListItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArchiveIcon from '@mui/icons-material/Archive';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import CampaignIcon from '@mui/icons-material/Campaign';
import { logoLight } from "assets";
import { useNavigate } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import { useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { useChat } from "contexts/ChatContext";
import KnowledgeBase from 'views/Home/components/KnowledgeBase';
import CustomInstructions from 'views/Home/components/HistorySidebar/components/CustomInstructions';
import { doc, updateDoc } from "firebase/firestore";
import { db } from "core/config/firebase";
import { ToggleHistory } from 'views/Home/components/ChatBot/components/ToggleHistory';
import { getCurrentUserId } from "core/api/api";
import { getDocs, collection, getDoc, writeBatch, deleteDoc } from "firebase/firestore";


interface Settings {
  title: string;
  path: string;
}

function Navbar() {
  const { userInfo, logout } = useAuth();
  const { saveHistoryBool, initializeNewSession } = useChat();
  const userId = getCurrentUserId();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState("");
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [toolsDialogOpen, setToolsDialogOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('additionalTools');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [knowledgeBaseOpen, setKnowledgeBaseOpen] = useState(false);
  const [instructionText, setInstructionText] = useState("");
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (userInfo) {
      setEmail(userInfo.email);
      setUserType(userInfo.user_type);
    }
  }, [userInfo]);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSettingsClick = () => {
    setToolsDialogOpen(true);
    handleCloseUserMenu();
  };

  const handleCloseDialog = () => {
    setToolsDialogOpen(false);
  };

  const handleKnowledgeBaseOpen = () => {
    setKnowledgeBaseOpen(true);
    handleCloseDialog();
  };

  const handleKnowledgeBaseClose = () => {
    setKnowledgeBaseOpen(false);
  };
  const handleInitializeNewSession = async () => {
    setOpen(false);
    await initializeNewSession();
  };

  const setInstructions = async (newInstructions: string) => {
    if (!userId) {
      console.error("User ID is not defined");
      return;
    }

    try {
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        chat_custom_instructions: newInstructions,
      });
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  const clearChatHistory = async () => {
    if (userId) {
      try {
        const userDocRef = doc(db, 'users', userId);
        const chatHistoryRef = collection(userDocRef, 'chat_history');

        const snapshot = await getDocs(chatHistoryRef);

        if (snapshot.empty) {
          return;
        }

        const batch = writeBatch(db);
        snapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit();
        console.log('Chat history cleared successfully.');
      } catch (error) {
        console.error('Error clearing chat history: ', error);
      }
      handleInitializeNewSession()
    }

  }
  const handleClearChatHistory = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmClear = () => {
    clearChatHistory();
    setOpenConfirmDialog(false);
  };
  const handleCancelClear = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <AppBar position="fixed">
        <Toolbar>
          <Box 
            mr="auto" 
            onClick={() => navigate('/')} 
            sx={{ cursor: 'pointer' }}
          >
            <Box
              component="img"
              src={logoLight}
              sx={{ width: "10vw", maxWidth: 70, maxHeight: 70 }}
            />
          </Box>

          <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
            <Typography 
              variant="h6" 
              sx={{ 
                color: 'white', 
                mr: 2,
                display: { xs: 'none', md: 'block' } // Hide on mobile
              }}
            >
              {email}
            </Typography>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar>
                {userInfo?.first_name && (
                  <>
                    {userInfo?.first_name.charAt(0)}
                    {userInfo?.last_name?.charAt(0)}
                  </>
                )}
              </Avatar>
            </IconButton>
            <Menu
              sx={{ 
                mt: "45px",
                '.MuiModal-root': {
                  overflowY: 'scroll',
                },
                '.MuiMenu-paper': {
                  maxHeight: '300px',
                  // backgroundColor: theme.palette.primary.main,
                  // color: 'white',
                },
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              disableScrollLock={true}
            >
              <MenuItem onClick={() => {
                navigate('/profile');
                handleCloseUserMenu();
              }}>
                <Typography textAlign="center" sx={{ color: 'theme.palette.primary.main' }}>Manage Subscription</Typography>
              </MenuItem>
              <MenuItem onClick={handleSettingsClick}>
                <Typography textAlign="center" sx={{ color: 'theme.palette.primary.main' }}>Settings</Typography>
              </MenuItem>
              <MenuItem onClick={() => {
                navigate('/support-request');
                handleCloseUserMenu();
              }}>
                <Typography textAlign="center" sx={{ color: 'theme.palette.primary.main' }}>Help</Typography>
              </MenuItem>
              <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center" sx={{ color: 'red' }}>Sign Out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Dialog
        open={toolsDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="tools-dialog-title"
        aria-describedby="tools-dialog-description"
        disableScrollLock={true}
        PaperProps={{
          sx: {
            width: isMobile ? '100%' : '65%',
            height: isMobile ? '40%' : '40%',
            borderRadius: '20px',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiDialogTitle-root': {
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
            },
            '& .MuiTypography-root': {
              color: 'white',
            },
            '& .MuiListItemIcon-root': {
              color: 'white',
            },
            '& .MuiDivider-root': {
              borderColor: 'rgba(255, 255, 255, 0.2)',
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            color: 'white',
            position: 'relative',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
          }}
          id="customized-dialog-title"
        >
          Settings
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 6,
              top: 4,
              color: 'white',
              '&:hover': {
                color: 'rgba(255, 0, 0)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box display="flex">
            <Box sx={{ width: isMobile ? 100 : 150, mr: 5, mt: 2, ml: -2 }}>
              <Typography
                sx={{
                  cursor: 'pointer',
                  padding: 1,
                  borderRadius: 2,
                  border: '2px solid',
                  borderColor: selectedMenu === 'additionalTools' ? 'white' : 'transparent',
                  backgroundColor: selectedMenu === 'additionalTools' ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  }
                }}
                onClick={() => setSelectedMenu('additionalTools')}
              >
                Additional Tools
              </Typography>
              <Typography
                sx={{
                  cursor: 'pointer',
                  padding: 1,
                  mt: 0.5,
                  borderRadius: 2,
                  border: '2px solid',
                  borderColor: selectedMenu === 'chatHistory' ? 'white' : 'transparent',
                  backgroundColor: selectedMenu === 'chatHistory' ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  }
                }}
                onClick={() => setSelectedMenu('chatHistory')}
              >
                Chat History
              </Typography>
            </Box>
            <Box flex={1} sx={{ mt: 1 }}>
              {selectedMenu === 'additionalTools' && (
                <List>
                  <ListItem button onClick={() => setDialogOpen(true)}>
                    <ListItemIcon>
                      <CampaignIcon />
                    </ListItemIcon>
                    <ListItemText primary="Custom Instructions" />
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={handleKnowledgeBaseOpen}>
                    <ListItemIcon>
                      <LocalLibraryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Knowledge Base" />
                  </ListItem>
                </List>
              )}
              {selectedMenu === 'chatHistory' && (
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <ArchiveIcon />
                    </ListItemIcon>
                    <ListItemText primary="Save Chat History" />
                    <ToggleHistory />
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={() => { clearChatHistory() }}>
                    <ListItemIcon sx={{ color: 'red' }}>
                      <DeleteOutlineIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Clear Chat History"
                      sx={{ color: 'red' }}
                    />
                  </ListItem>
                </List>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCancelClear}
      >
        <DialogTitle sx={{ backgroundColor: theme.palette.primary.main, color: 'white', height: '55px', mt: '-5px' }}>
          Confirm Clear Chat History
        </DialogTitle>
        <DialogContent sx={{ mt: '20px', mb: '-10px', fontSize: '1.1rem' }}>
          Are you sure you want to clear your chat history?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClear} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmClear} color="primary" sx={{ color: 'red' }} >
            Clear
          </Button>
        </DialogActions>
      </Dialog>
      <CustomInstructions
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            instructionText={instructionText}
            setInstructionText={setInstructionText}
            setInstructions={setInstructions}
          />
      <KnowledgeBase open={knowledgeBaseOpen} onClose={handleKnowledgeBaseClose} />
    </Box>
  );
}

interface MobileMenuProps {
  handleLogout: () => void;
  navigate: (path: string) => void;
  navItems: { title: string; path: string }[];
}

function MobileMenu({ handleLogout, navigate, navItems }: MobileMenuProps) {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMobileNavigation = (path: string) => {
    navigate(path);
    setMobileOpen(false);
  };

  return (
    <>
      <Box
        alignItems={"center"}
        sx={{
          display: { xs: "flex", md: "none" },
          backgroundColor: theme.palette.primary.light,
          borderRadius: "8px",
          marginRight: 2,
        }}
      >
        {/* <Button
          aria-label="Menu"
          variant={"outlined"}
          onClick={handleDrawerToggle}
          sx={{
            borderRadius: 2,
            minWidth: "auto",
            padding: 1,
            color: "black",
          }}
        >
          <MenuIcon />
        </Button> */}
      </Box>
      <Drawer
        anchor={"right"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 250,
            borderRadius: "8px",
            color: theme.palette.text.primary,
          },
        }}
      >
        <Box
          onClick={handleDrawerToggle}
          sx={{
            width: 250,
          }}
        >
          <List>
            {navItems.map((page, index) => (
              <ListItemButton
                key={index}
                onClick={() => handleMobileNavigation(page.path)}
                sx={{
                  backgroundColor:
                    location.pathname === page.path
                      ? theme.palette.secondary.main
                      : theme.palette.primary.contrastText,
                }}
              >
                <ListItemText
                  primary={page.title}
                  sx={{
                    fontWeight:
                      location.pathname === page.path ? "bold" : "normal",
                  }}
                />
              </ListItemButton>
            ))}
          </List>
          <Divider />
          <ListItemButton color="inherit" onClick={handleLogout}>
            Sign Out
          </ListItemButton>
        </Box>
      </Drawer>
    </>
  );
}

export default Navbar;
