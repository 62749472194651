import { getAuth } from "@firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';



const firebaseConfig = {
  apiKey: "AIzaSyB4UFlOK9CcmDOdBhY-MrDgkdtgxV2rAtc",
  authDomain: "aa-darby-prod-7fef4.firebaseapp.com",
  projectId: "aa-darby-prod-7fef4",
  storageBucket: "aa-darby-prod-7fef4.appspot.com",
  messagingSenderId: "775855855564",
  appId: "1:775855855564:web:1162f062a0e99166c42f69",
  measurementId: "G-YGX3MGZE47"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig ? firebaseConfig : {});
const auth = getAuth(app);

export { auth };
export const db = getFirestore(app);
export const storage = getStorage(app);
