import { API_URL } from 'core/config/index';



export const login = async (credentials: LoginCredentials) => {
  
  {/* OAuth2PasswordRequestForm in backend requires FormData */}
  const formData = new FormData();
  formData.append('username', credentials.email);
  formData.append('password', credentials.password);
  
  const request = new Request(`${API_URL}/token`, {
    method: 'POST',
    body: formData,
  });
  const response = await fetch(request);
  return response;
};

//@deprecated
export const decodeAccessToken = async (token: string) => {
  
  const request = new Request(`${API_URL}/decode-access-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ access_token: token }),
  });
  const response = await fetch(request);
  return response;
};

//@move
export const signUp = async (credentials: SignUpCredentials) => {


  const request = new Request(`${API_URL}/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: credentials.email,
      first_name: credentials.firstName,
      last_name: credentials.lastName,
      password: credentials.password,
      user_type: "client",
    }),
  });
  const response = await fetch(request);
  return response;
};

//@deprecated
export const verify = async (token: string) => {
  const request = new Request(`${API_URL}/verify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ token }),
  });
  const response = await fetch(request);
  return response;
  // const data = await response.json();
  // if (data['status'] === 'ok') {
  //   return true
  // } else {
  //   return false
  // }
};

export const forgotPassword = async (email: string) => {
  const request = new Request(`${API_URL}/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email: email }),
  });
  const response = await fetch(request);
  return response;
};


export const resetPassword = async (password: string, token: string) => {
  const request = new Request(`${API_URL}/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ password: password, token: token }),
  });
  const response = await fetch(request);
  return response;
};

export const decodeResetToken = async (token: string) => {

  const request = new Request(`${API_URL}/decode-reset-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ token: token }),
  });
  const response = await fetch(request);
  return response;
}




