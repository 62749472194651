import React, { useEffect, useState } from 'react';
import MainLayout from 'layouts/MainLayout';
import { Box, Container, Avatar, Typography, Dialog, DialogContent } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { useAuth } from 'contexts/AuthContext';
import { ManageSubscription } from './components/ManageSubscription';
import { getStripeSubStatusFromFirestore } from 'core/api/getStripeSubStatusFromFirestore';
import { ChatProvider } from 'contexts/ChatContext';
import ProfileBot from 'views/Home/components/ProfileBot/ProfileBot';
import { getCurrentUserId } from "core/api/api";

const Profile = (): JSX.Element => {
    const [showChat, setShowChat] = useState(false);
    const [subscriptionData, setSubscriptionData] = useState(null);
    const { userInfo } = useAuth();
    const uid = getCurrentUserId();

    const userData = {
        name: userInfo?.first_name + " " + userInfo?.last_name,
        first_name: userInfo?.first_name,
        last_name: userInfo?.last_name,
        email: userInfo?.email,
        company: userInfo?.company_name,
        user_type: userInfo?.user_type
    };

    useEffect(() => {
        if (uid) {
            getStripeSubStatusFromFirestore(uid)
                .then(async ({ status, data }) => {
                    setSubscriptionData(data);
                });
        }
    }, [uid]);

    const handleOpenChat = () => {
        setShowChat(true);
    };

    const handleCloseChat = () => {
        setShowChat(false);
    };

    return (
        <MainLayout>
            <Box sx={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2}}>
                <ChatProvider currentUser={userInfo}>
                    <Container component="main" sx={{ maxWidth: { m: "lg" }, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 2, p: 4, bgcolor: 'background.paper', outline: 'none' }}>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', mb: 3 }}>
                            <Avatar sx={{ m: 2, bgcolor: deepPurple[500], width: 100, height: 100 }}>
                                {userData.first_name?.charAt(0)}{userData.last_name?.charAt(0)}
                            </Avatar>
                            <Typography variant="h4" sx={{ textAlign: 'center', m: 2 }}>
                                {userData.name}
                            </Typography>
                        </Box>
                        <ManageSubscription 
                            userData={userData} 
                            subscriptionData={subscriptionData} 
                            handleOpenChat={handleOpenChat}
                        />
                    </Container>

                    <Dialog 
                        open={showChat} 
                        onClose={handleCloseChat} 
                        fullWidth 
                        maxWidth="md"
                        sx={{ 
                            '& .MuiDialog-paper': { 
                                height: '80vh',
                                maxHeight: '80vh',
                                m: 2,
                                borderRadius: '16px 16px 8px 8px', 
                                overflow: 'hidden'
                            } 
                        }}
                    >
                        <DialogContent sx={{ p: 0, display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <ProfileBot uid={uid!} onClose={handleCloseChat} />
                        </DialogContent>
                    </Dialog>
                </ChatProvider>
            </Box>
        </MainLayout>
    );
};

export default Profile;
