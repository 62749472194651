import React from 'react';
import { Button, CircularProgress, Box, Typography, Divider } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import theme from 'theme';

interface CustomerViewProps {
    isLoading: boolean;
    handleGoToChat: () => void;
    alterSubscriptionUrl: string;
    userData: any;
    subscriptionData: any;
    handleUpdateProfile: () => void;
}

const CustomerView: React.FC<CustomerViewProps> = ({ isLoading, handleGoToChat, alterSubscriptionUrl, userData, subscriptionData, handleUpdateProfile }) => (
    <Box mt={1}>
        {/* <Typography variant='h4' sx={{ textAlign: 'center', mb: 1, color: theme.palette.primary.main }}>{userData.name}</Typography> */}
        {/* <Typography variant='h5' sx={{ textAlign: 'center', mb: 3, color: theme.palette.primary.main }}>Your Current Plan</Typography> */}
        {isLoading ? (
            <CircularProgress />
        ) : (
            <Box sx={{ 
                flex: 1, 
                boxShadow: 3, 
                borderRadius: 12, 
                width: { xs: 325, md: 800 }, 
                mb: 2,
                backgroundColor: 'white',
                color: theme.palette.primary.main,
                minHeight: '500px',
                border: `3px solid ${theme.palette.primary.main}`,
            }}>
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    padding: '40px',
                }}>
                    <Typography 
                        variant='h5' 
                        color={theme.palette.primary.main} 
                        sx={{ 
                            fontWeight: 'bold',
                            width: '100%',
                            mb: 3,
                        }}
                    >
                        {userData.user_type === 'customer' ? 'Subscribed' : 'Not Subscribed'}
                    </Typography>

                    <Box sx={{ display: 'flex', flexDirection: 'row', marginY: 1 }}>
                        <CheckIcon sx={{ color: 'green', mr: 1 }}/>
                        <Typography variant='body1' color={theme.palette.primary.main} sx={{ fontWeight: 500, fontSize: '1.2rem' }}>Unlimited access to the DARBy chatbot</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', marginY: 1 }}>
                        <CheckIcon sx={{ color: 'green', mr: 1 }}/>
                        <Typography variant='body1' color={theme.palette.primary.main} sx={{ fontWeight: 500, fontSize: '1.2rem' }}>Continue any conversation from a previous session</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', marginY: 1 }}>
                        <CheckIcon sx={{ color: 'green', mr: 1 }}/>
                        <Typography variant='body1' color={theme.palette.primary.main} sx={{ fontWeight: 500, fontSize: '1.2rem' }}>Find the exact source text for your queries directly from the PDF</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', marginY: 1 }}>
                        <CheckIcon sx={{ color: 'green', mr: 1 }}/>
                        <Typography variant='body1' color={theme.palette.primary.main} sx={{ fontWeight: 500, fontSize: '1.2rem' }}>Customize your chatbot to fit your needs</Typography>
                    </Box>

                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'flex-end',
                        mb: 2,
                        mt:2
                    }}>
                        <Typography 
                            variant='body1' 
                            color={theme.palette.primary.main}
                            sx={{ 
                                alignSelf: 'flex-start',
                                mt: 0.5,
                                fontSize: '1.1rem',
                            }}
                        >
                            $
                        </Typography>
                        <Typography 
                            variant='h4'
                            color={theme.palette.primary.main}
                            sx={{ mr: 1 }}
                        >
                            {(subscriptionData?.items[0].price.unit_amount / 100).toFixed(2)}
                        </Typography>
                        <Box sx={{ 
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            mb: 0.5,
                        }}>
                            <Typography 
                                variant='body2' 
                                color={theme.palette.primary.main}
                                sx={{ 
                                    fontSize: '0.6rem',
                                    lineHeight: 1,
                                }}
                            >
                                USD/
                            </Typography>
                            <Typography 
                                variant='body2' 
                                color={theme.palette.primary.main}
                                sx={{ 
                                    fontSize: '0.6rem',
                                }}
                            >
                                {subscriptionData?.items[0].price.recurring.interval}
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Typography variant='h6' color={theme.palette.primary.main}>
                            Next Billing Date:&nbsp;
                        </Typography>
                        <Typography variant='body1' color={theme.palette.primary.main}>
                            {new Date(subscriptionData?.current_period_end.seconds * 1000).toLocaleDateString()}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: { md: 'row', xs: 'column' }, 
                    alignItems: 'center', 
                    justifyContent: 'space-evenly', 
                    paddingTop: 0, 
                    height: { md: 50, xs: 150 },
                    padding: '2px 2px 40px 2px',
                    mt: 0,
                }}>
                    <Button 
                        variant="outlined" 
                        onClick={handleGoToChat} 
                        sx={{ 
                            width: { xs: '75%', md: '25%' },
                            color: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                            borderWidth: '2px',
                            borderRadius: '25px',
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'red',
                                borderColor: 'red',
                                borderWidth: '2px',
                            }
                        }}
                    >
                        GO TO CHAT
                    </Button>
                    <Button 
                        variant="outlined"
                        onClick={handleUpdateProfile} 
                        sx={{ 
                            width: { xs: '75%', md: '25%' }, 
                            mt: { xs: 1, md: 0 },
                            color: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                            borderWidth: '2px',
                            borderRadius: '25px',
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'red',
                                borderColor: 'red',
                                borderWidth: '2px',
                            }
                        }}
                    >
                        Update Profile
                    </Button>
                    <Button 
                        variant="outlined"
                        href={alterSubscriptionUrl} 
                        target='_blank' 
                        sx={{ 
                            width: { xs: '75%', md: '25%' }, 
                            mt: { xs: 1, md: 0 },
                            color: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                            borderWidth: '2px',
                            borderRadius: '25px',
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'red',
                                borderColor: 'red',
                                borderWidth: '2px',
                            }
                        }}
                    >
                        Alter Subscription
                    </Button>
                </Box>
            </Box>
        )}
    </Box>
);

export default CustomerView;
