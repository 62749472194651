import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { NotFound, IsLoading, SessionExpired, AccountDeactivated } from 'components';
import { ClientRoute, SuperUserRoute, ProtectedRoute, AuthRoute, CustomerRoute } from './components';
import { useAuth } from 'contexts/AuthContext';
import { Login, Signup, ForgotPassword, ResetPassword } from 'views/Auth';
import { Profile, SupportRequest } from 'views/Shared';
import NeedVerification from 'components/NeedVerification';
import Home from 'views/Home'

export const AppRoutes = () => {
  const { isLoading, userInfo, isVerified, getUserInfo } = useAuth();


  if (isLoading) {
    return (
      // This is loading state used to check for Authentication status - for some reason it doesn't redirect to not found with out this 
      <IsLoading />
    );
  }


  if (userInfo?.is_active === false) {
    return (
      <AccountDeactivated />
    );
  }

  if (!isVerified() && userInfo) {
    return (
      <NeedVerification />
    );

  }


  const AuthRoutes = () => {

    return (
      <>
        <Route path="/login" element={
          <AuthRoute>
            <Login />
          </AuthRoute>
        } />

        <Route path="/signup" element={
          <AuthRoute>
            <Signup />
          </AuthRoute>
        } />

        <Route path="/forgot-password" element={
          <AuthRoute>
            <ForgotPassword />
          </AuthRoute>
        } />

        <Route path="/reset-password" element={
          <AuthRoute>
            <ResetPassword />
          </AuthRoute>
        } />
      </>
    );
  }


  const ClientRoutes = () => {
    return (
      <>
        <Route path="/profile" element={
          <ClientRoute>
            <Profile />
          </ClientRoute>
        } />

      </>
    );
  };

  const CustomerRoutes = () => {
    return (
      <>
        <Route path="/" element={
          <CustomerRoute>
            <Home />
          </CustomerRoute>
        } />

      </>
    );
  };


  const SharedRoutes = () => {


    return (
      <>

        {/* Profile */}
        <Route path="/profile" element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        } />

        {/* Support Request */}
        <Route path="/support-request" element={
          <ProtectedRoute>
            <SupportRequest />
          </ProtectedRoute>
        } />

        {/* Not Found - other pages redirect here */}
        <Route path="/not-found" element={
          <ProtectedRoute>
            <NotFound />
          </ProtectedRoute>
        } />

        {/* Not Found - other pages redirect here */}
        <Route path="/account-deactivated" element={
          <ProtectedRoute>
            <AccountDeactivated />
          </ProtectedRoute>
        } />

        {/* Redirect * to NotFound to hide Routes  */}
        <Route path="*" element={
          <ProtectedRoute>
            <Navigate to="/not-found" replace />
          </ProtectedRoute>
        } />


      </>
    );
  }


  return (
    <Router>
      <Routes>

        {AuthRoutes()}

        {ClientRoutes()}

        {SharedRoutes()}

        {CustomerRoutes()}

      </Routes>
    </Router>
  );
}