import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Tooltip,
} from "@mui/material";
import { db } from "core/config/firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { getCurrentUserId } from "core/api/api";
import { useAuth } from "contexts/AuthContext";
import { Edit } from "@mui/icons-material";
import CampaignIcon from '@mui/icons-material/Campaign';
import theme from "theme";

type CustomInstructionProps = {
  dialogOpen: boolean;
  setDialogOpen: (dialogOpen: boolean) => void;
  instructionText: string;
  setInstructionText: (text: string) => void;
  setInstructions: (newInstructions: string) => void;
}

const CustomInstructions = ({ dialogOpen, setDialogOpen, instructionText, setInstructionText, setInstructions}: CustomInstructionProps): JSX.Element => {

  const userId = getCurrentUserId();

  useEffect(() => {
    const fetchInstructions = async () => {
      try {
        if (userId) {
          const docRef = doc(db, "users", userId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            const latestInstructions = data.chat_custom_instructions || "";
            setInstructionText(latestInstructions);
          } else {
            console.log("No such document!");
          }
        }
      } catch (error) {
        console.error("Error fetching instructions:", error);
      }
    };

    if (dialogOpen) {
      fetchInstructions();
    }
  }, [dialogOpen, setInstructionText]);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleInstructionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInstructionText(event.target.value);
  };

  const handleInstructionSubmit = async () => {
    try {
      await setInstructions(instructionText);
      console.log("custom instructions successfully updated on firestore");
      handleDialogClose();
    } catch (error) {
      console.error("Error updating instructions:", error);
    }
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.primary.main,
          borderRadius: '20px',
          '& .MuiDialogTitle-root': {
            color: 'white',
          },
          '& .MuiDialogContentText-root': {
            color: 'rgba(255, 255, 255, 0.7)',
          },
        },
      }}
    >
      <DialogTitle>Enter Custom Instructions</DialogTitle>
      <DialogContent>
        <DialogContentText>
          These Instructions Will Persist Across Conversations
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          placeholder="Enter Custom Instructions"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          rows={7}
          value={instructionText}
          onChange={handleInstructionChange}
          sx={{ 
            height: "300px",
            '& .MuiOutlinedInput-root': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: '10px',
              border: '2px solid white',
              color: 'white',
              '& fieldset': {
                borderColor: 'white',
                borderWidth: '2px',
              },
              '&:hover fieldset': {
                borderColor: 'white',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'white',
            },
            '& textarea::placeholder': {
              color: 'rgba(255, 255, 255, 0.7)',
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={handleDialogClose} 
          sx={{ 
            color: 'white',
            '&:hover': {
              color: 'red',
              backgroundColor: 'transparent',
            }
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleInstructionSubmit} 
          sx={{ 
            color: 'white',
            '&:hover': {
              color: 'red',
              backgroundColor: 'transparent',
            }
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomInstructions;
