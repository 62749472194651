import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useMemo,
} from "react";
import {
  User,
  UserCredential,
  onAuthStateChanged,
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  getAdditionalUserInfo,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "@firebase/auth";
import { initializeApp } from "firebase/app";
import { auth } from "core/config/firebase";
import { app } from "core/config/firebase";
import { removeJwtToken, setJwtToken, getJwtToken } from "core/api/api";
import { getFirestore } from "firebase/firestore";
import {
  createUser,
  getUserProfile,
  checkUserRole,
  signUpAndSendEmail,
} from "core/api/firebase";
import { getUTCtimeNow } from "core/utils";
import { handleLoginWithGoogle } from "../core/firebase/handleLoginWithGoogle";
import { handleLoginWithEmail } from "../core/firebase/handleLoginWithEmail";
import { resendVerificationEmail } from "core/api/functions";
import { getStripePayments } from "@invertase/firestore-stripe-payments";
import HyperDX from '@hyperdx/browser'

type AuthContextType = {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  userInfo: UserInfoType | null;
  setUserInfo: (userInfo: UserInfoType) => void;
  isSuperuser: () => boolean;
  isLoading: boolean;
  loginWithEmail: (credentials: LoginCredentials) => Promise<any>;
  loginWithGoogle: () => Promise<any>;
  logout: () => void;
  // verify: (token: string) => Promise<Response>;
  handleResendVerificationEmail: (email: string) => Promise<boolean>;
  handleSendPasswordResetEmail: (email: string) => Promise<boolean>;
  handleVerifyPasswordResetCode: (code: string) => Promise<boolean>;
  handleConfirmPasswordReset: (
    code: string,
    newPassword: string
  ) => Promise<boolean>;
  getUserInfo: () => Promise<UserInfoType | null>;
  signUp: (credentials: SignUpCredentials) => Promise<boolean>;
  isVerified: () => boolean;
  payments: any;
};

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true); // handle auth check loading state
  const [currentUserRole, setCurrentUserRole] = useState("");
  const [isExpired, setIsExpired] = useState(false);

  // const [isSuperuser, setIsSuperuser] = useState(false); // isSuperuser state
  const isSuperuser = () => {
    return userInfo ? userInfo.user_type === "admin" : false;
  };
  const isVerified = () =>
    userInfo ? userInfo.email_verified && userInfo.is_active : false;


  const payments = getStripePayments(app, {
    productsCollection: "products",
    customersCollection: "customers",
  });
  

  const loginWithEmail = async (credentials: LoginCredentials) => {
       return await handleLoginWithEmail(
        credentials.email,
        credentials.password,
        setUserInfo,
        setIsAuthenticated
      );
  };

  const loginWithGoogle = async () => {
    return await handleLoginWithGoogle(setIsAuthenticated, setUserInfo);
  };

  const logout = async () => {
    try {
        await signOut(auth);
        setIsAuthenticated(false);
        window.location.reload(); // Refresh the page after sign out
        console.log('refreshing page')
    } catch (error) {
        console.error("Error signing out: ", error);
    }
};

  const handleResendVerificationEmail = async (email: string) => {
    try {

      await resendVerificationEmail(email);
      return true;
    } catch (error) {
      console.error("Error resending verification email:", error);
      return false;
    }
  }

  const handleSendPasswordResetEmail = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error) {
      console.error("Error sending password reset email:", error);
      return false;
    }
  };

  const handleVerifyPasswordResetCode = async (code: string) => {
    try {
      const response = await verifyPasswordResetCode(auth, code);
      return true;
    } catch (error) {
      console.error("Error verifying password reset code:", error);
      return false;
    }
  };

  const handleConfirmPasswordReset = async (
    code: string,
    newPassword: string
  ) => {
    try {
      const response = await confirmPasswordReset(auth, code, newPassword);
      return true;
    } catch (error) {
      console.error("Error confirming password reset:", error);
      return false;
    }
  };

  const refreshUserData = async (currentUser: User) => {
    const jwtToken = await currentUser?.getIdToken();
    if (jwtToken) {
      setJwtToken(jwtToken);
    }
    const docSnapData = await getUserProfile(currentUser.uid);
    const verified = currentUser.emailVerified;
    const data = { ...currentUser, ...docSnapData, email_verified: verified};
    if (docSnapData) {
      if (!docSnapData.is_active) {
        console.log("User is not active");
      }

      setUserInfo(data); // Set the state here
    } else {
      console.log("Failed to fetch user data or user does not exist.");
    }
  };

  const getUserInfo = async () => {
    const currentUser = auth.currentUser;
    return (await getUserProfile(currentUser?.uid)) as UserInfoType | null;
  };
  const signUp = async (credentials: SignUpCredentials) => {
    return await signUpAndSendEmail(credentials, auth);
  };

  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = onAuthStateChanged(auth, async (user) => {

      setIsAuthenticated(!!user);
      if (user) {
        if (user.email) {
          HyperDX.setGlobalAttributes({
            userId: user.uid,
            userEmail: user.email,
          })
        }
        await refreshUserData(user);
      }
      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        userInfo,
        setUserInfo,
        isSuperuser,
        isLoading,
        isVerified,
        loginWithEmail,
        getUserInfo,
        loginWithGoogle,
        logout,
        signUp,
        handleResendVerificationEmail,
        handleSendPasswordResetEmail,
        handleVerifyPasswordResetCode,
        handleConfirmPasswordReset,
        payments,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};