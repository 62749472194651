import React from 'react';
import { Box, Typography } from '@mui/material';
import incognito_icon from 'assets/incognito_icon.png';

const Incognito = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            position="relative"
            top="50%"
            left="50%"
            sx={{ transform: 'translate(-50%, 0)' }}
            p={2}
        >
                <>
                    <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                        Incognito Mode
                    </Typography>
                    <Box
                        component="img"
                        src={incognito_icon}
                        alt="Incognito mode"
                        sx={{
                            width: "1.8em",
                            height: "1.8em",
                            borderRadius: "50%",
                        }}
                    />
                </>
        </Box>
    );
};

export default Incognito;
