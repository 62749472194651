import React from 'react';
import {
  GoogleAuthProvider,
  signInWithPopup,
  getAuth,
  getAdditionalUserInfo,
} from "@firebase/auth";
import { setJwtToken } from 'core/api/api';
import { createUser, getUserProfile } from 'core/api/firebase';
import { getUTCtimeNow } from 'core/utils/getUTCTimeNow';

export const handleLoginWithGoogle = async (setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>, setUserInfo: React.Dispatch<any>) => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      const userCredentialObj = await signInWithPopup(auth, provider);

      const credential = GoogleAuthProvider.credentialFromResult(userCredentialObj);
      if (!credential) {
        console.error('No credentials found');
        return false;
      }
      const token = await userCredentialObj.user.getIdToken();
      if (!token) {
        throw new Error('No access token found.');
      }
      setJwtToken(token);

      setIsAuthenticated(true);

      const additionalUserInfo = getAdditionalUserInfo(userCredentialObj);
      const firebaseUserProfile = await getUserProfile(userCredentialObj.user.uid)
      const isNewUser = additionalUserInfo?.isNewUser || firebaseUserProfile === null;


      if (isNewUser) {
        const user = userCredentialObj.user;
        const data = user.toJSON();

        const utcTimeNow = getUTCtimeNow();

        const additionalData = {
          name: userCredentialObj.user.displayName,
          email: userCredentialObj.user.email,
          user_type: 'client',
          is_active: true,
          email_verified: true,
        }


        setUserInfo(data);
        const docSnapData = await createUser(user, additionalData);
      }
      setUserInfo(firebaseUserProfile);
      return credential;
    } catch (error) {
      console.error('Error logging in with Google:', error);
      return false;
    }
  };