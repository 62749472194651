import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, Box, Button, Typography } from '@mui/material';
import { AuthLayout } from 'layouts';
import { useAuth } from 'contexts/AuthContext';

const AccountDeactivated = () => {
    // const navigate = useNavigate();
    const { logout, setIsAuthenticated } = useAuth();
    const theme = useTheme();

    const handleLoginRedirect = () => {
        setIsAuthenticated(false);
        logout();
        window.location.href = '/login';
        // navigate('/login');
    };
    
    return (
        <AuthLayout>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: theme.spacing(2),
                    textAlign: 'center',
                }}
            >
            <Typography variant="h4" gutterBottom>
                Account Inactive
            </Typography>
            <Typography variant="body1" gutterBottom>
                Your account has either not yet been activated or an Admin has disabled your access. Please contact admin@agingaircraftsolutions.com for further information.
            </Typography>
            <Button 
            variant="contained"
             color="primary" 
             onClick={handleLoginRedirect}
             sx={{ mt: theme.spacing(2) }}
             >
                Go to Login
            </Button>
        </Box>

    </AuthLayout >
  );
};

export default AccountDeactivated;
