import HyperDX from '@hyperdx/browser'

type HyperDxActionEvent = 'login' | 'submit-application' | 'Feedback'

interface ILogHyperDxAction {
  action: HyperDxActionEvent
  data: { [key: string]: string }
}

export const logHyperDxAction = async (props: ILogHyperDxAction) => {
  const { action, data } = props
  HyperDX.addAction(action, data)
}