import React, { useEffect, useState } from 'react';
// import { PDFLoader } from './PDFLoader';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    IconButton,
    Typography,
    Box,
} from '@mui/material';
import { fetchPDF } from 'core/api/getPdfFromFbStorage';
import CloseIcon from '@mui/icons-material/Close';
import theme from 'theme';

export const PDFDialog = ({ pdfData, open, handleClose }: any) => {
    const { fileName, page } = pdfData;


    const [pdfUrl, setPdfUrl] = useState<string | null>(null);

    useEffect(() => {
        const returnPDFBlob = async () => {
            try {
                const url = await fetchPDF(fileName);
                if (url) {
                    const fullPdfUrl = page ? `${url}#page=${page}` : url;
                    setPdfUrl(fullPdfUrl);
                }
            } catch (error) {
                console.error('Error fetching PDF:', error);
                setPdfUrl(null);
            }
        };

        if (fileName && open) {
            returnPDFBlob();
        }
    }, [fileName, page, open]);


    const handleDownload = () => {
        if (pdfUrl) {
            // pdfUrl is already a blob URL here
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };


    return (
        <>
            {(pdfUrl) && (
                <Dialog
                    fullWidth
                    maxWidth="xl"
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            minHeight: '95vh',
                            backgroundColor: theme.palette.primary.main,
                        },
                    }}
                    disableScrollLock={true}
                >
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        pr: 8,
                        pl: 3,
                        pt: 2,
                        pb: 0,
                    }}>
                        <DialogTitle sx={{ 
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '1.5rem',
                            p: 0,
                            position: 'absolute',
                            left: '24px',
                        }}>
                            PDF Viewer
                        </DialogTitle>
                        <Button 
                            onClick={handleDownload} 
                            sx={{ 
                                color: 'white',
                                '&:hover': {
                                    color: 'red',
                                }
                            }}
                        >
                            Download PDF
                        </Button>
                        <IconButton
                            edge="end"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 25,
                                top: 8,
                                color: 'white',
                                '&:hover': {
                                    color: 'red',
                                },
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <DialogContent style={{ height: '85vh', overflowY: 'hidden' }}>
                        <iframe
                            src={pdfUrl}
                            style={{
                                width: '100%',
                                height: '100%',
                                border: 'none',
                                backgroundColor: 'white',
                                borderRadius: '10px',
                            }}
                        ></iframe>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
}