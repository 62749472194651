import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";

const AuthRoute = ({ children }: any) => {
  const { isAuthenticated, userInfo } = useAuth();
  const userType = userInfo?.user_type;

  if (isAuthenticated && userType) {
    if (userType === 'client') {
      console.log('Client Auth Route navigate, user is a: ', userType)
      return <Navigate to="/profile" replace />;
    }
    else {
    console.log('Customer Auth Route Navigate, user is a: ', userType)
    return <Navigate to="/" replace />;
  }
  }
  return children;
};

export default AuthRoute;