import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';


// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#30242e',
      light: '#e9ece7',
      dark: '#30242e',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#3024e',
    },
    error: {
      main: red.A400,
    }

  },
  typography: {
    fontFamily: 'Outfit',
  },

});

export const authTextFieldStyle = () => ({
  backgroundColor: 'white',
  borderRadius: theme.shape.borderRadius,
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    '& fieldset': {
      border: '1px solid #000000',
    },
    '&:hover fieldset': {
      border: '1px solid #000000',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #000000',
    },
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(14px, -15px) scale(0.75)', // adjust for when the label shrinks
  },
});


export default theme;
